import React from 'react'
import { Button, TextField, Typography,} from '@material-ui/core/'
// import { withRouter } from "react-router-dom"
import axios from "axios"
// import { useHistory } from "react-router-dom";
import { end_point } from '../helper'

 class Login extends React.Component {
  constructor(props) {
    super(props)
      this.state = {
        username : "",
        password: "",
        token: "",
        openAlert: false,
        verification : "",
      }
  }

  handleInputChange = e => {
    const name = e.target.name
    const value = e.target.value
    this.setState({
      [name]: value
    })
  }

  handleLogin = async e => {
    e.preventDefault()
    // let history = useHistory();
    let doc = {
      token: localStorage.getItem("token"),
      username: this.state.username,
      password: this.state.password
    }
    console.log("DOC", doc)
    const usersResponse = await axios.get(`${end_point()}/login`, {
      headers: { 
        doc: encodeURI(JSON.stringify(doc))
      } 
    });
    console.log("USERRESPONSE", usersResponse.data)

    let user = usersResponse.data
    if(user.status === 200){
      localStorage.setItem("token", user.token)
      localStorage.setItem("expires", user.expires_at)
      this.props.history.push("/console");
    }else{
      alert("Account Information Incorrect")
    }

    }


  render() {
    return (
      <div>        
        <div style={{ display: "flex", justifyContent: "space-around", marginTop: "5em"}} >
      
          <div style={{width: "30vw"}}>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form noValidate >
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                onChange={this.handleInputChange}
                value={this.state.username}
                autoComplete="username"
                autoFocus
              />
              <TextField
                variant="standard"
                margin="normal"
                required
                fullWidth
                onChange={this.handleInputChange}
                value={this.state.password}
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />   
              <Button
                style={{marginTop: "1em"}}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                onClick={this.handleLogin}
              >
                Login
              </Button>
            </form>
          </div>
        </div>
      </div>
    )
  }
}

export default Login