import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faTwitter, faVimeoV, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { Avatar, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

const Footer = () => {
  const classes = useStyles();
  return (
    <Grid container className={classes.footer}>
      <Grid item xs={12} sm={6} className={classes.padding}>
        <Typography className={classes.footerText}>
          &copy; {moment().year()} Population Reference Bureau. All Rights Reserved.
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} className={classes.footerIcons}>
        <a target="_blank" href="https://www.facebook.com/events/online/youth-ncds/415601485638466/"><Avatar className={classes.icon}><FontAwesomeIcon icon={faFacebookF} size="xs"/></Avatar></a>
        <a target="_blank" href="https://twitter.com/PRBdata"><Avatar className={classes.icon}><FontAwesomeIcon icon={faTwitter} size="xs"/></Avatar></a>
        {/* <a target="_blank" href="https://vimeo.com/"><Avatar className={classes.icon}><FontAwesomeIcon icon={faVimeoV} size="xs"/></Avatar></a>
        <a target="_blank" href="https://www.instagram.com/"><Avatar className={classes.icon}><FontAwesomeIcon icon={faInstagram} size="xs"/></Avatar></a> */}
      </Grid>
    </Grid>
  )
}

export default Footer

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    border: "1px solid #e6e6e6",
    margin: '0 0.5em',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#abbb03',
    },
  },
  footer: {
    padding: '1.5em 12%',
    left: '0',
    bottom: '0',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    zIndex: '10',
    borderTop: '1px solid #f1f1f1'
  },
  footerIcons: {
    display: 'inline-flex',
    padding: '0 !important',
    justifyContent: 'flex-end',
    '@media only screen and (max-width: 500px)': {
      justifyContent: 'center',
    }
  },
  footerText: {
    color: '#4e4f57',
    marginTop: '0.5em',
    '@media only screen and (max-width: 500px)': {
      marginBottom: '1em',
      fontSize: '0.7em'
    }
  },
  padding: {
    padding: '0 !important'
  }
}));