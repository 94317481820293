import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import * as serviceWorker from './serviceWorker'

const THEME = createMuiTheme({
  typography: {
    fontFamily: 'Metropolis',
    h2: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#16192c',
      textAlign: 'center',
      padding: '1em 0'
    },
    h4: {
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#16192c',
      textAlign: 'center',
      padding: '1em 0'
    },
    body1: {
      fontSize: '1em',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.7',
      letterSpacing: 'normal',
      color: '#4e4f57'
    },
  },
  GridListTile: {
    padding: '1em',
    height: 'initial'
  }
})
ReactDOM.render(
  <MuiThemeProvider theme={THEME}>
    <App />
  </MuiThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
