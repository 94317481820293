import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios'
import {
  Table, 
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination, 
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel

} from '@material-ui/core/';
import moment from 'moment'
import LinkIcon from '@material-ui/icons/Link';
import { Link } from 'react-router-dom'



const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableContainer : {
    marginTop: "2em"
  }
});



export default function SimpleTable(props) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [filter, setFilter] = React.useState("all")
  
  useEffect(() => {
    console.log("BLOGPOSTS", props.blogPosts)
  }, [])


  const handleChange = (event) => {
    setFilter(event.target.value);
    setPage(0)
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  console.log("Filter:", filter)
  var typeFilter = []
  switch (filter) {
    case 'all':
      var sortbyDate = props.blogPosts.sort((a, b) => a.created_at - b.created_at)
      console.log("sort?", sortbyDate)
      typeFilter = props.blogPosts
      break;
    case 'archived':
      typeFilter = props.blogPosts.filter(post => post.deleted)
      break;
    case 'draft':
      typeFilter = props.blogPosts.filter(post => !post.published && !post.deleted)
      break 
    case 'live':
      typeFilter = props.blogPosts.filter(post => post.published && !post.deleted)
      break
    default:
      console.log(`Unfamiliar with ${filter} filter` );
  }

  const blogPosts = typeFilter
  
  const capitalize = (s) => {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  console.log("POSTS:", props.blogPosts)
  const statuses = ['all', 'live', 'draft', 'archived']
  return (
    <div style={{marginBottom: '2em'}}>
      <FormControl component="postType" style={{width:"100%",marginTop:"2em"}}>
        <FormLabel component="legend">Select Status:</FormLabel>
        <RadioGroup row aria-label="position" value={filter} onChange={handleChange} name="position" defaultValue="all">
          {
            statuses.map(status => {
              return (
                <FormControlLabel
                value={status}
                control={<Radio color="primary" />}
                label={capitalize(status)}
                labelPlacement="start"
              />
              )
            })
          }
      </RadioGroup>
      </FormControl>
      <TableContainer className={classes.tableContainer} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead style={{background: "lightgrey"}}>
            <TableRow>
              <TableCell>Title</TableCell> 
              <TableCell>Status</TableCell> 
              <TableCell>Created At</TableCell> 
              <TableCell>Action</TableCell> 
              <TableCell>Preview</TableCell> 
            </TableRow>
          </TableHead>
          <TableBody>
          {
            blogPosts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(({_id, title, published, created_at, link, type, deleted}) => (
            <TableRow key={_id}>
                <TableCell>{title}</TableCell>
                <TableCell>{deleted ? "Archived" : published ? "Live" : "Draft"}</TableCell>
                <TableCell>{moment(created_at).format("MMMM Do YYYY")}</TableCell>
                <TableCell>
                  <a><div style={{color: '#0000EE'}} onClick={()=> {props.getPost(_id)}}> Edit</div></a>
                  {
                    !deleted &&
                    <>
                    <br/>
                    <a><div style={{color: '#0000EE'}} onClick={()=> {props.deleteBlogPost(_id)}}> Archive</div></a>
                    </>
                  }
                </TableCell>
                <TableCell>
                {
                  type === "link" ?
                  <a target="_blank" href={link}><LinkIcon style={{padding: "10px", color:"#0000ee"}} /></a>
                  :
                  <Link to={{ pathname: `/blogpage/${_id}`}}>
                    <LinkIcon style={{padding: "10px", color:"#0000ee"}} />
                  </Link>
                }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={blogPosts.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}