import React from "react";
import { Switch, Route, Link} from "react-router-dom";
import {Breadcrumbs, Typography} from '@material-ui/core/'
import BlogPostForm from "./BlogPostForm"
import EditPosts from "./EditPosts"

import {isSessionValid, noAuthentication} from "../authorize/CheckSession"

class Console extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: "edit",
      editor: false
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  goBackToEditTable = () => {
    this.setState({editor: false})
  }

  goToEditPost = () => {
    this.setState({editor: true})
  }

  handleEdit = () =>{
    this.setState({tab: 'edit'})
    this.goBackToEditTable()
  }

  render() {
    return (
      <div>
      {isSessionValid() ? 

      <div className="margin">
        <Typography variant="h4">
          <Link color="inherit" to="/console" onClick={() => window.location.reload()}>
            BLOG POSTS
          </Link>
          </Typography>
        <Breadcrumbs aria-label="breadcrumb" separator={"|"}>
          <Link color="inherit" to="/">
            HOME PAGE
          </Link>
          <Link color="inherit" to="/console" onClick={this.handleEdit}>
            BLOG LISTINGS {/* EDIT OR REMOVE */}
          </Link>
          <Link color="inherit" to="/console" onClick={() => this.setState({tab: 'new'})}>
            CREATE NEW
          </Link>
        </Breadcrumbs>

        {this.state.tab === "new"? <BlogPostForm/> : null }
        {this.state.tab === "edit"? <EditPosts goToEditPost={this.goToEditPost} goBackToEditTable={this.goBackToEditTable} editor={this.state.editor}/> : null }

      </div> 
      : noAuthentication() 
      }
      </div>

    );
  }
}

export default Console;
