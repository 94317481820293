import React, {Component} from 'react'
import EditPostTable from "./EditPostTable"
import BlogPostForm from "./BlogPostForm"
import axios from 'axios'
import { end_point } from '../helper'

export default class EditPosts extends Component {
  constructor(props) {
    super(props)
      this.state = {
        blogPosts: [],
        editBlog: {},
    }
  }


  componentDidMount() {
    this.getPosts()
  }

  getPosts = async () => {
    const response = await axios.get(`${end_point()}/blogs`, {})

    this.setState({ blogPosts: response.data })
    return response.data 
  }

  getPost = async (id) =>{
    const response = await axios.get(`${end_point()}/blogs/${id}`, {})
    this.setState({ editBlog: response.data })
    this.props.goToEditPost()
    return response.data
  }
  
  deleteBlogPost = async (id) => {
    try {
      console.log("delete Blog Post", id)
      const response = await axios.patch(
        `${end_point()}/blogs/${id}/delete`,
        {},
        {
          headers: {
            token: localStorage.getItem("token")
          }
        }
      );
      console.log("response", response);
      window.location.reload()
      // message.success("Your post was was archived.");
      // this.loadBlogPost()
    } catch (err) {
      console.log("Err", err);
      // message.warning("Your post wasn't archived.");
    }
  };


  render() {
    return (
      <div className="">
        {this.props.editor ?
        <BlogPostForm
          edit={this.props.editor}
          editBlog={this.state.editBlog}
        />
        :
        <EditPostTable
          deleteBlogPost={this.deleteBlogPost}
          blogPosts={this.state.blogPosts}
          getPost={this.getPost}
        /> 
      }
      </div>
    )
  }
}
