import React from 'react'
import {BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import HomePage from './components/homePage'
import BlogPage from './components/blogPage'
import Console from "./components/blogConsole/Console"
import Login from "./components/authorize/Login"
import './App.css';

const App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path="/" render={props => <HomePage {...props} />}/>
          <Route path="/blogpage/:id" render={props => <BlogPage {...props} />}/>
          <Route path="/console" render={props => <Console {...props} />} />
          <Route path="/login" render={props => <Login {...props} />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
