import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Card, CardActionArea, CardActions, CardContent, CardMedia, Button, Typography, Link } from '@material-ui/core'

const RecentUpdates = () => {
  const classes = useStyles();
  return (
    <div className="recent-updates">
      <div className="margin">
        <Typography variant="h4">Recent Updates</Typography>
        <Grid container spacing={3} className="container">
        {
          updates.map((card, i) => {
            return (
              <Grid key={i} item xs={12} sm={4} className="tile">
                <Link className={classes.link} href={card.link} target="_blank">
                  <Card className={classes.card} elevation={0}>
                    <CardActionArea className={classes.content}>
                      <CardMedia
                        component="img"
                        alt="update image"
                        height="180"
                        image={card.img}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="subtitle1" className={classes.title}>
                          {card.title}
                        </Typography>
                        <Typography variant="body2" component="p" className="ru-descriptions">
                          {card.description}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Link>
              </Grid>
          )})
        }
        </Grid>
      </div>
    </div>
  )
}

export default RecentUpdates

const updates = [
  { title: 'Data Center', description: 'Access prevalence among youth on the key behavioral risk factors for NCDs—tobacco use, alcohol use, unhealthy diet, and physical inactivity—as well as some mental health indicators.', img: 'data-center_thumb.png', link: 'https://app-prod.youthandncds.org'},
  { title: 'Insights Feature', description: 'See why focusing on youth is key to curbing the growing NCD epidemic around the world and what can be done to address the behavioral risk factors among youth.', img: 'insights_thumb.png', link: '../insights'},
  { title: 'Country Resources', description: 'Access country- and region-specific publications pertaining to the NCD risk factors and mental health issues among youth.', img: 'country-resource_thumb.png', link: 'https://app-prod.youthandncds.org/countryresources'}
]

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    border: "1px solid #e6e6e6",
    margin: '0 0.5em',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#abbb03',
    },
  },
  title: {
    textAlign: 'center',
    color: '#16192c',
    fontWeight: '600'
  },
  button: {
    width: '100%',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  link: {
    fontWeight: 'bold',
    color: '#abbb03 !important',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  card: {
    height: '100%',
    border: 'solid 1px #f1f1f1',
    '&:hover': {
      boxShadow: '0 10px 40px 0 rgba(7, 7, 13, 0.07)'
    }
  },
  content: {
    '&:hover': {
      backgroundColor: 'transparent',
      background: '#ffffff',
      '-webkit-tap-highlight-color': 'tomato',
    }
  }
}));