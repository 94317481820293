import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom'
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Grid, Typography, GridList, GridListTile } from '@material-ui/core'
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import LaunchIcon from '@material-ui/icons/Launch';
import axios from 'axios'
import { end_point } from './helper'

const WhatsNew = () => {
 const [blogPosts, setBlogPosts] = useState([])

  useEffect(() => {
    axios.get(`${end_point()}/blogs`, {})
    .then(response => {
      console.log('blogPosts?', response.data)
      var activePosts = response.data.filter(post => {
        if (post.published && !post.deleted) {
        }
      })

      // var activePosts = response.data.filter(post => post.published && !post.deleted)
      console.log("Active posts", activePosts)
      response.data.forEach(post => {
        if (post.published !== false && activePosts.length < 7) {
          activePosts.push(post)
        }
      })
      setBlogPosts(activePosts)

    })
  }, [])
  
  const classes = useStyles();

  const sideScroll = (direction) => {
    var start = document.getElementById(`start-btn`).style
    var end = document.getElementById(`end-btn`).style
    var element = document.getElementById('scroll-container')

    var step = window.innerWidth < 500 ? 300 : 550
    start.zIndex = 1

    var scrollAmount = 0;
    var slideTimer = setInterval(() => {
      if(direction == 'left'){
        element.scrollLeft -= 10;
      } else {
        element.scrollLeft += 10;
      }
      scrollAmount += 10;
      if(scrollAmount >= step) {
        window.clearInterval(slideTimer);
      }
    }, 5);

    var newScrollLeft = element.scrollLeft
    var width = element.clientWidth
    var scrollWidth = element.scrollWidth
    
    if ((scrollWidth - width) - Math.ceil(newScrollLeft) < step) {
      start.zIndex = 1
      end.zIndex = -1
    } else if (Math.ceil(newScrollLeft) === 0 || Math.ceil(newScrollLeft) < step) {
      end.zIndex = 1
      start.zIndex = -1
    } else if ((scrollWidth - width) - Math.ceil(newScrollLeft) > step) {
      end.zIndex = 1
    }
}

  return (
    <div className="whats-new">
      <Typography variant="h4" className={classes.whatsNew}>Here’s What’s New</Typography>
      <div className={classes.root}>
        <GridList className={classes.gridList} cols={4} id="scroll-container">
          {
            blogPosts.map((item, index) => {
              console.log("blogpost", item,  item.heading.length)
              let id = '' 
              if (index === 0) {
                id = 'start'
              } else if (index === (blogPosts.length -1)) {
                id = 'end'
              }
              var post = (
                <Grid container spacing={0}>  
                  <Grid item xs={2} className={classes.title}>0{index + 1}.</Grid>
                  <Grid item xs={10}>
                    <Typography className={`${classes.date} date-caption`} variant="caption">
                      {moment(item.created_at).format('LL')}&nbsp;<LaunchIcon style={{fontSize: '1.5em'}}/>
                    </Typography>
                    <Typography variant="body2" className={classes.title}>
                      <b>{item.title}</b>
                    </Typography>
                    <Typography variant="caption" className={classes.title}>
                      {item.heading}
                    </Typography>
                  </Grid>
                </Grid>
              )

              return (
                <GridListTile key={index} className={classes.tileContent} id={id}>
                  {
                    item.type === "link" ?
                    <a target="_blank" href={item.link}>{post}</a>
                    :
                    <Link to={{ pathname: `/blogpage/${item._id}`, state: { item }}}>
                      {post}
                    </Link>
                  }
                </GridListTile>
              )
            }  
          )}
        </GridList>
        <Avatar id='start-btn' className={`${classes.icon} left-icon`} onClick={() => sideScroll('left')}>
          <FontAwesomeIcon icon={faChevronLeft} size="sm"/>
        </Avatar>
        <Avatar id='end-btn' className={`${classes.icon} right-icon`} onClick={() => sideScroll('right')}>
          <FontAwesomeIcon icon={faChevronRight} size="sm"/>
        </Avatar>
      </div>
    </div>
  )
}

export default WhatsNew

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    overflow: 'hidden',
  },
  gridList: {
    flexWrap: 'nowrap',
    transform: 'translateZ(0)',
    width: '100%'
  },
  whatsNew: {
    color: '#ffffff',
    padding: '0.5em',
    position: 'relative',
    '@media only screen and (max-width: 500px)': {
      fontSize: '6vw',
      paddingBottom: '0.2em',
    }
  },
  title: {
    color: '#ffffff',
  },
  tileContent: {
    padding: '1em !important',
    height: 'max-content !important',
    '&:hover': {
      backgroundColor:'#abbb03',
      cursor: 'pointer'
    },
    '&:hover .date-caption': {
      color: '#ffffff'
    },
    '@media only screen and (max-width: 500px)': {
      width: '20em !important',
    }
  },
  date: {
    color: '#abbb03',
    fontWeight: 'bold',
    fontSize: '0.7rem'
  },
  card: {
    padding: '0',
    backgroundColor: 'transparent',
  },
  icon: {
    marginTop: '-7em',
    backgroundColor: '#abbb03',
    fontSize: '0.8rem',
    boxShadow: '0 10px 50px 0 rgba(171, 187, 3, 0.8)',
    marginLeft: '1em',
    marginRight: '1em',
    width: theme.spacing(7),
    height: theme.spacing(7),
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));


// https://stackoverflow.com/questions/56392199/make-a-button-to-scroll-horizontally-in-div