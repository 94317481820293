import React from "react"
import Footer from "./footer"
import { Link } from 'react-router-dom'
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Acknowledgments from "./acknowledgements"
import RecentUpdates from "./recentUpdates"
import WhatsNew from "./whatsNew"



const HomePage = () => {
  
  const classes = useStyles();

  return(
    <div>
      <div className="homepage-container" >  {/* style={{minHeight:'110%'}} */}
        <img src="hero-final1.png" alt="hero image" className="hero-image"/>
{/*
        <Link to="/" onClick={() => window.location.reload()}>
          <img src="logo.png" alt="logo" className="logo-img margin"/>
        </Link>
        <Typography variant="h1" className={classes.title}> 
          <span style={{color: "#abbb03"}}>Youth</span> and <br/> Noncommunicable Diseases
        </Typography>
*/}
      </div>
      <WhatsNew />
      <RecentUpdates />
      <Acknowledgments />
      <Grid item xs={12} sm={12} style={{marginLeft: '11%', paddingBottom: '1em'}}>
        <Grid container spacing={0} className={classes.contactInfo}>
          <Grid item xs={2} sm={1}>
            <Avatar className={classes.icon}><FontAwesomeIcon icon={faEnvelope} size="xs" color="#4e5158"/></Avatar>
          </Grid>
          <Grid item xs={10} sm={11} container>
            <Grid item xs={8} container direction="column" spacing={2}>
              <Grid item xs>
                <Typography variant="body2" className="mail-link">Email us:</Typography>
                <Typography><a className="mail-link" href="mailto:youthandncds@prb.org"><b>YouthandNCDs@prb.org</b></a></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="margin">
        <Typography variant="body1" className={classes.contactUs}>Photo Credits</Typography>
        <Typography variant="caption">
          Homepage: Marco Betti.  Insight Feature: Fat Camera / Getty Images, Shani Turke PMA2020 / Photoshare, JF Leblanc / Alamy Stock Photo, Gawrav / Getty Images, Jonathan Torgovnik / Getty Images, Monkeybusinessimages / Getty images, HD SIGNATURE CO.,LTD / Alamy Stock Photo.<br/><br/>
        </Typography>
      </div>
      <Footer />
    </div>
  )
}

export default HomePage

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    border: "1px solid #e6e6e6",
    margin: '0 0.5em',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#abbb03',
    },
  },
  title: {
    fontSize: '5vw',
    fontWeight: '900',
    lineHeight:'1.1',
    color: '#ffffff',
    margin: '0 12%',
    top: '450px',
    position: 'absolute',
    // // width: '100%',
    // textAlign: 'right',
    // // paddingRight: '2%',

  },
  contactUs: {
    textAlign: 'left',
    paddingTop: '0',
    '@media only screen and (max-width: 500px)': {
      textAlign: 'center',
    },
    display: 'block'
  },
}));