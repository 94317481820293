import Switch from '@material-ui/core/Switch'
import { withStyles } from '@material-ui/core/styles'

const PRBSwitch = withStyles(theme => ({
  switchBase: {
    color: '#abbb03',
    '&$checked': {
      color: '#abbb03'
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(171,187,3, 0.6)',
    },
  },
  checked: {},
  track: {},
}))(Switch);

export default PRBSwitch
