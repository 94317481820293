import React, { useEffect, useState } from "react"
import { Link } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Typography } from '@material-ui/core'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios'
import { end_point } from './helper'
import CircularProgress from '@material-ui/core/CircularProgress';

const BlogPage = (props) => {
  const [item, setItem] = useState({})
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    const id = props.match.params.id;
    console.log("ID:", id)
    axios.get(`${end_point()}/blogs/${id}`, {})
    .then(response => {
      console.log('blogPosts?', response.data)
      setItem(response.data)
      setLoading(false)
    })
  }, [])
  
  const classes = useStyles();
  return (
    <div className="blog-page margin">   
      {loading ? 
      <div className={classes.spinner}><CircularProgress size={70} /></div> :
        <div>
          <Typography variant="h2">{item.title}</Typography>
          <Typography variant="h5">Published by: {item.author}</Typography>
          <Typography>{moment(item.created_at).format('MMMM Do YYYY')}</Typography>
          <Typography>
          {ReactHtmlParser(item.body)}
          </Typography>

          <Link to="/">
            <Avatar className={classes.icon}>
              <FontAwesomeIcon icon={faChevronLeft} size="s"/> 
            </Avatar>
          </Link>
        </div>   
      }
    </div>
  )
}

export default BlogPage

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: 'center',
    color: '#16192c',
    fontWeight: '600'
  },
  icon: {
    backgroundColor: '#abbb03',
    margin: '0 auto'
  },
  spinner: {
    textAlign: "center",
    margin: "12em"
  }
}));
