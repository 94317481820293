import React from "react"
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'


const Acknowledgments = () => {
  const classes = useStyles();
  return (
    <div className="acknowledgments">
      <Typography variant="h4">Acknowledgments</Typography>
      <div className="margin">
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography>
              The Youth and NCDs Data Center and related analytical work were funded by the AstraZeneca Young Health Programme (YHP). YHP is a disease prevention programme with a unique focus on adolescents. It was founded in partnership with Johns Hopkins Bloomberg School of Public Health and Plan
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Typography>
          International, with local NGO partners implementing YHP programs on the ground. The YHP mission is to positively impact the health of adolescents in marginalized communities worldwide through research, advocacy, and on-the-ground programs focused on NCD prevention.
          </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.logos}>
          <Grid item><a target="_blank" href="https://www.prb.org/"><img src="prbfooter.jpg" alt="prb logo" className="sponsor-img"/></a></Grid>
          <Grid item><a target="_blank" href="https://plan-uk.org/donate/partnerships/private-sector/partnering-with-astrazeneca"><img src="PI_Logo.jpg" alt="plan-international logo" className="sponsor-img"/></a></Grid>
          <Grid item><a target="_blank" href="https://www.younghealthprogrammeyhp.com/"><img src="AZ_YHP.jpg" alt="AstraZeneca logo" className="sponsor-img"/></a></Grid>
        </Grid>
      </div>
    </div>
  )
}

export default Acknowledgments

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  icon: {
    border: "1px solid #e6e6e6",
    margin: '0 0.5em',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: '#abbb03',
    },
  },
  logos: {
    marginTop: '2em',
    justifyContent: 'space-between',
    '@media only screen and (max-width: 500px)': {
      justifyContent: 'center'
    }
  }
}));