export const isSessionValid = () => {
  // remove session if expired
  let token = localStorage.getItem("token")
  let tokenValid = token === "" || token === null ? false : true
  console.log("IS TOKEN VALID", tokenValid)
  let expiresAt = localStorage.getItem("expires")

  let sessionSet = expiresAt === null ? false : true
  console.log("SESSION VALID", sessionSet)
  let expired = new Date().getTime() > expiresAt ? true : false
  console.log("EXPIRED", expired)
  if( expired && !sessionSet && !tokenValid) {
    localStorage.removeItem("token")
    localStorage.removeItem("expires")
    console.log("EXPIRED")
    return false
  } else {
    console.log("VALID")
    return true
  }
}

export const noAuthentication = () => {
  document.location.replace('/login')
}