import React, { useState, useEffect } from 'react'
import ReactQuill, {Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
// import { ImageUpload } from 'quill-image-upload';
import { useForm } from "react-hook-form";
import { Button, Typography, TextField, FormControlLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PRBSwitch from '../toggle';
import axios from 'axios'
import { end_point } from '../helper';
import _ from "lodash/fp";
// Quill.register('modules/imageUpload', ImageUpload);

const BlogPostForm = (props) => {
  const [editorHtml, setHtml] = useState('')
  const [blogType, setType] = useState(false)
  const [isDraft, setDraft] = useState(false)
  const [isPreview, setPreview] = useState(false)
  const [date, setDate] = useState()
  
  const { handleSubmit, register, errors } = useForm({
    defaultValues: {
      title: props.editBlog ? props.editBlog.title : "",
      heading: props.editBlog ? props.editBlog.heading : "",
      author: props.editBlog ? props.editBlog.author : "",
      body: props.editBlog ? props.editBlog.body : "",
      link: props.editBlog ?  props.editBlog.link : "",
      created_at: props.editBlog ? props.editBlog.created_at : new Date().toISOString().slice(0,10) // not working
    } 
  })
  
  useEffect(() => {
    if (props.editBlog) {
      console.log('props?', props,  'date', props.editBlog.created_at)
      setType(props.editBlog.type === "post" ? true : false) // to show either blog post or link field
      setHtml(props.editBlog.body)
      var date = props.editBlog.created_at.slice(0,10)
      setDate(date)
    } else {
      console.log("setting date")
      setDate(new Date().toISOString().slice(0,10))
    }
  }, [])


  const onSubmit = (values, e ) => {
    values.body = blogType ? editorHtml : null
    values.type = blogType ? 'post' : 'link'
    values.published = isDraft || isPreview ? false : true
    values.created_at = new Date(date) //.toISOString()
    console.log("onSubmit values?", values)
    props.edit ? updateBlog(values, e) : postBlog(values, e)
  };

  const postBlog = (values, e) => {
    console.log('posting blog', values)
    axios.post(`${end_point()}/blogs`, { data: values })
    .then(response => {
      console.log('posted blog post?', response.data)
      if (isPreview) {
        document.location.href = `blogpage/${response.data._id}`
      } else {
      console.log('posted blog post?', response)
      e.target.reset()
      setHtml("")
      window.location.reload(false);
      }
    })
  }

  const updateBlog = (values, e) => {
    if (props.editBlog.deleted && isDraft) {
      values.deleted = false
    }
    console.log('updating blog', values)
    axios.patch(`${end_point()}/blogs/${props.editBlog._id}`, { data: values })
    .then(response => {
      console.log(' edit posted blog post?', response.data.value)
      if (isPreview) {
        let post = response.data.value
        document.location.href = `blogpage/${post._id}`
      } else {
      console.log('edit posted blog edit?', response)
      e.target.reset()
      setHtml("")
      window.location.reload(false);
      }
    })
  }
  
  // const SaveAsDraft = () => {
  //   if (props.editBlog && props.editBlog.deleted) {
  //     console.log('if post was previously archived, unarchived')
  //     setDelete(false)
  //   }
  //   setDraft(true)
  // }

  const classes = useStyles();
  var errorField = <Typography variant="caption" className={classes.caption}>Required Field</Typography>
  return (
    <div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.root}>
        <TextField fullWidth label="Title" name="title" inputRef={register({ required: true })} />
        { errors.title && errorField }
        <TextField fullWidth label="Sub Heading" name="heading" inputRef={register({ required: true, maxLength: 90 })}/>
        { errors.heading && errors.heading.type === "required" && errorField }
        { errors.heading && errors.heading.type === "maxLength" &&  <Typography variant="caption" className={classes.caption}>Max 90 Characters allowed</Typography>}
        {
          blogType &&
          <>
            <TextField name="author" inputRef={register({ required: true })} className={classes.textField} label="Author"/>
            { errors.author && errorField }
          </>
        }
        <br/>
        <br/>
        <Typography variant="body1" display="inline">Link</Typography>
        <FormControlLabel control={<PRBSwitch checked={blogType} name="type" onChange={(e) => setType(e.target.checked)}/>} label="Blog" />
        </div>
        {
          blogType ?
          <div className="blog-form">
            <ReactQuill
            theme="snow"
            value={editorHtml}
            onChange={setHtml}
            modules={modules}
            formats={formats}
            placeholder={"Write a post"}
            name="body"
            />
            { errors.body && errorField }
          </div>
          :
          <div className={classes.root}>
            <TextField fullWidth name="link" inputRef={register({ required: true })} label="Link"/>
            { errors.link && errorField }
          </div>
        }
        {
          date &&
          <div className={classes.root}>
            <TextField
              id="created_at"
              name="created_at"
              label="Date of Posting"
              type="date"
              defaultValue={date}
              onChange={e => setDate(e.target.value)}
            />
            { errors.created_at && errorField }
          </div>
        }
        <Button type="submit" className={classes.btn}>
          { props.edit ? "Save" : "Create Post"}
        </Button>
        <Button style={{ margin: "0 1em 0"}} type="submit" className={classes.btn} onClick={() => setDraft(true)}>
          {
            props.editBlog && props.editBlog.deleted ? 'Unarchive and Save as Draft' : 'Save as Draft'
          }
        </Button>
        <Button className={classes.cancelBtn} href="/console">
          Cancel
        </Button>
        {
          blogType ?
          <Button style={{ margin: "1em 1em 0 1em"}} className={classes.cancelBtn} type="submit" onClick={()=> setPreview(true)}>
            Save Draft and Preview
          </Button> : null
        }   
      </form>
    </div>
  )
}

export default BlogPostForm

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  textField: {
    width: '31%',
  },
  caption: {
    color: '#abbb03', // '#757579',
    marginTop: '-6px',
    marginBottom: '0',
    display: 'block',
    fontWeight: 'bold',
  },
  switch: {
    color: '#abbb03',
    '&$checked': {
      color: 'rgba(171,187,3, 0.4)'
    }
  },
  btn: {
    color: 'white',
    backgroundColor: '#007dc3',
    margin: '1em 0em',
    fontWeight: '600',
    '&:hover': {
      color: '#007dc3',
    },
  },
  cancelBtn: {
    border: '1px solid grey',
    float: 'right',
    margin: '1em 0em',
  }
}));

var toolbarOptions = [
  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
  ['blockquote', 'code-block'],
  [{ 'header': 1 }, { 'header': 2 }],               // custom button values
  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
  [{ 'direction': [] }],                            // text direction
  [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
  ["link", "image", "video"],
  [{ 'align': [] }],
  ['clean']                                         // remove formatting button
];

var toolSave = [
  // [{ header: "1" }, { header: "2" }, { font: [] }],
  ["bold", "italic", "blockquote"],
  [{ size: [] }],
  [
    { list: "ordered" },
    { list: "bullet" },
    { indent: "-1" },
    { indent: "+1" }
  ],
  ["link", "image"],
  ["clean"]
]

const modules = {
  // imageUpload: {
  //   url: '' , // server url. If the url is empty then the base64 returns
  //   // method: 'POST', // change query method, default 'POST'
  //   // name: 'image', // custom form name
  //   // // withCredentials: false, // withCredentials
  //   // headers: {
  //   //   token: localStorage.getItem("access_token"),

  //   //  // ${"https://06klh3jmzl.execute-api.us-east-1.amazonaws.com/prod"}
  //   // }, // add custom uploader // add custom headers, example { token: 'your-token'}
  //   customUploader: async (url, callback, base) => {
  //      console.log("URL", url)
  //      console.log("BASE", base)
  //      console.log("Callback", callback)
  //     //  this.showImageUploader()
  //      let file = url
  //      let reader = new FileReader();  
  //      reader.onloadend = async () => {
  //          console.log('RESULT', reader.result)
  //          var img = new Image();
  //          img.src= reader.result
  //   let dime = img.onload = await function() {
  //     // console.log("this", this.width)
  //      let imgw = img.width
  //      let imgh = img.height
  //      console.log("{w:imgw , h: imgh}", imgw, imgh)
  //      return {w:imgw , h: imgh} // image is loaded; sizes are available
  //   };

  //   let height = dime().h 
  //   let width = dime().w 
  //   console.log("DIME", height, width)
  //   console.log("DIME2", dime())
  //          const body = JSON.stringify({ image: reader.result });
  //          const response = await axios.post(
  //            `${end_point()}/images/blog-body`,
  //            body,
  //            { headers: { 
  //              token: localStorage.getItem("access_token"),
  //              width: width,
  //              height: height,
  //             } }
  //          );
     
  //            console.log("RESPONSE", response)
  //            callback(`${'https://s3.amazonaws.com/test-home.youthandncds.org'}/${response.data.key}`)

  //        }   
  //       // let upload = this.uploadImage()     
  //       // console.log("upload?", upload) 
  //       console.log("DATA1", file)     
  //      let data = await reader.readAsDataURL(file);
  //        console.log("DATA", data)


  //   },
  //   // personalize successful callback and call next function to insert new url to the editor
  //   // callbackOK: (serverResponse, next) => {
  //   //     // next(serverResponse);
  //   //     console.log("RESPONSE CALLBACK", serverResponse)
  //   //     console.log("NEXT", next)
  //   // },
  //   // // personalize failed callback
  //   // callbackKO: serverError => {
  //   //     // alert(serverError);
  //   //     console.log("image upload error", serverError)
  //   // }
  // },
    toolbar: toolbarOptions,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: true
    }
  };
  
  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
    "color",
    "align"
  ];
  